import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Box, { BoxParagraph, BoxTitle } from '../elements/box';
import Card, { CardContent, CardImage } from '../elements/card';

const Therapie_individuelle = () => (
  <Layout>
    <Box background="primary-light">
      <BoxTitle title="La Thérapie individuelle"/>
      <BoxParagraph>
        <p>En gestalt, la thérapie vise à intégrer cinq dimensions principales : physique, affective, cognitive, sociale 
            et spirituelle <em>(cf. le Pentagramme de Ginger)</em>.
            Son originalité n'est pas dans ses techniques mais plutôt dans son objectif :
            augmenter la capacité d'adaptation à des êtres ou des environnements différents, 
            restaurer la liberté de choix. 
            Elle place le client comme acteur du changement, et la relation comme moteur de ce changement.</p>
        <p>La Gestalt réhabilite le ressenti émotionnel, encore trop souvent censuré par la culture occidentale,
           en en favorisant l'expression. Celle-ci peut se trouver torrentielle aux débuts d'une thérapie et demande 
           donc à être canalisée par un professionnel expérimenté, des cas de décompensation ayant été signalés à ce stade.</p>
      </BoxParagraph>
      <div className="columns">
        <div className="column is-5">
          <Card height="">
            <CardImage src="illustration3" alt="illustration" size="is-4by3" />
          </Card>
        </div>
        <div className="column">
          <BoxParagraph>
          <p>Certaines formes de thérapie sont centrées sur le pourquoi et recherchent l'origine du traumatisme. Ce sont des 
            « thérapies de l'amont » qui en cernant la cause (voire en l'extrayant) se donnent pour objectif d'éliminer les 
            conséquences futures. Les conséquences passées étant par nature non éliminables, mais seulement relativisables.</p>
          <p>D'autres courants sont des « thérapies de l'aval » : en laissant de côté les origines de nos blocages, ces thérapies 
            cherchent à libérer le comportement, à « déboucher la rivière » et « nettoyer les berges », pour lui permettre de 
            couler plus librement.</p>
          <p>En poursuivant, la Gestalt est une « thérapie du courant » du « mouvement » : ce qui est important c'est le processus, 
            plutôt que le pourquoi. Cette focalisation sur le processus (mot qui signifie progrès, marche en avant, en latin), 
            se retrouve dans le vocabulaire gestaltiste : la frontière-contact, une Gestalt inachevée, les ruptures du contact. </p>
          </BoxParagraph>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <BoxParagraph>
          <p>Pour la Gestalt-thérapie la difficulté est regardée dans le cadre de l'<b>ici et maintenant</b>. </p>
          <p>Le Gestalt-thérapeute s’efforce donc essentiellement d’accompagner le cours de la présence, en aidant le client à vivre 
            plus consciemment son expérience, à la «déplier» : <em>qu’est-ce que je ressens, qu’est-ce que je pense, qu’est-ce que j’imagine,
            qu’est-ce que je dis vraiment à travers ce que je dis, quel sens je donne à ce mot, qu’est-ce que je veux, qu'est-ce que je 
            demande, qu’est-ce que j’évite, qu’est-ce que je suis en train de faire, qu'est-ce que j'attends de l'autre, etc</em>... </p>
          <p>Cette <b>intensification de la conscience immédiate</b> permet non seulement de mettre à jour ses modalités habituelles 
            d'être au monde - projections, interprétations, peurs, routines, fixations, etc. -mais surtout rend possible 
            l'expérience et donc l'assimilation de nouvelles modalités.</p>
          </BoxParagraph>
        </div>
        <div className="column is-5">
          <Card height="">
            <CardImage src="illustration4" alt="illustration" size="is-4by3" />
          </Card>
        </div>
      </div>
      <BoxParagraph>
        <p>Travailler à son autonomie affective, développer sa capacité d’affirmation, faire des choix, prendre ses responsabilités, 
          contacter sa créativité, sortir des fixations et remettre du mouvement, ce sont des possibilités qui nous permettent de
          nous sentir plus en contact avec nous et les autres.</p>
        <p>La relation thérapeutique n’est pas un moyen pour effectuer un changement, elle est elle-même une situation de 
          changement. J’attache une importance particulière au cadre de la thérapie qui permet de créer un espace de sécurité 
          bienveillant pour explorer de nouvelles manières d’être en relation.</p>
      </BoxParagraph>
    </Box>
    <div className="columns">
      <div className="column is-half is-offset-one-quarter">
        <Link to='/la_gestalt'>
          <Card>
            <CardContent>
              <BoxParagraph>
                <p>Pour en découvrir plus sur la <b>Gestalt</b>.</p>
              </BoxParagraph>
            </CardContent>
            <CardImage src="gestalt" alt="La Gestalt" hoverable="true"/>
          </Card>
        </Link>
      </div>    
    </div>
    
  </Layout>
)

export default Therapie_individuelle;
